<template>
  <hb-modal
    size="medium"
    :title="`Delete ${capitalizeLetter(promoData.promotion_type)}?`"
    v-model="dialog"
    confirmation
    @close="close()"
  >
    <template v-slot:content>
      <div class="content py-4 px-6 d-flex flex-column">
        <div v-html="popupDescription"></div>
      </div>
    </template>
    <template v-slot:right-actions>
      <hb-btn :loading="loading" color="destructive" @click="deletePromo"
        >Continue</hb-btn
      >
    </template>
  </hb-modal>
</template>
<script>
import api from "../../../assets/api.js";

import { notificationMixin } from "@/mixins/notificationMixin.js";
import { capitalizeFirstLetter } from "../../../utils/common";
import { EventBus } from "../../../EventBus";

export default {
  name: "DeletePromoPopUp",
  components: {},
  mixins: [notificationMixin],
  props: {},
  data() {
    return {
      dialog: false,
      promoData: {},
      popupDescription: "",
      loading: false,
    };
  },
  computed: {
    getApprovalConfirmationMessage() {
      return `Are you sure you want to <span class="font-weight-medium">delete</span> the ${this.promoData.promotion_type} '<span class="font-weight-medium">${this.promoData.promotion_name}</span>'?`;
    },
  },
  methods: {
    close() {
      this.$emit("close", this.promoData?.promotion_id ?? "");
    },
    show(data) {
      this.dialog = true;
      this.promoData = data.rowData;
      this.popupDescription = this.getApprovalConfirmationMessage;
    },
    capitalizeLetter(text) {
      return capitalizeFirstLetter(text);
    },

    async deletePromo() {
      this.loading = true;
      try {
        await api
          .delete(this, api.PROMOTIONS + this.promoData.promotion_id)
          .then(() => {
            this.showMessageNotification({
              type: "success",
              description: `${this.capitalizeLetter(
                this.promoData.promotion_type
              )} has been deleted successfully.`,
            });
            this.dialog = false;
            this.$emit("deleted");
            EventBus.$emit("refreshDiscountTable")
          })
          .catch((error) => {
            console.log(error);
            this.showMessageNotification({
              type: "error",
              description: `${this.capitalizeLetter(
                this.promoData.promotion_type
              )} deletion failed.`,
            });
          });
      } catch (error) {
        console.log(error, "Promo deletion API failed");
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  gap: 16px;
}
</style>
