<template>
  <div class="d-flex">
    <div class="d-flex">
      <span class="hb-inline-sentence-text">If occupancy is ≥</span>
      <div>
        <hb-text-field
          condensed
          box
          medium
          v-model="threshold.min"
          type="number"
          v-validate
          data-vv-name="min"
          data-vv-as="Occupancy"
          :error="error.min"
          placeholder="0"
          min="0"
          max="99"
        >
          <template v-slot:append> % </template>
        </hb-text-field>
      </div>
      <span class="hb-inline-sentence-text">apply</span>
      <div class="pt-1 pm-select">
        <hb-autocomplete
          v-model="threshold.promotion_id"
          :items="propertyList"
          item-value="id"
          item-text="name"
          v-validate
          data-vv-name="promotion_id"
          data-vv-as="Promotion"
          :error="error.promotion_id"
          label="Select Promotion"
        />
      </div>
    </div>
    <HbBtn
      v-if="index != 0"
      icon
      tooltip="Close"
      mdi-code="mdi-close"
      @click="$emit('remove', index)"
    />
  </div>
</template>
<script>
export default {
  name: "OccupancyThresholdSetting",
  data() {
    return {
      error: {
        min: false,
        promotion_id: false,
      },
    };
  },
  computed: {
    threshold: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    propertyList() {
      return [
        {
          name: 'No Promotion',
          id: 'no-promotion'
        },
        ...this.promotions
      ]
    }
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    value: {
      type: Object,
      required: true,
      default: () => {},
    },
    promotions: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        if(val.id && val.promotion_id == null)
        this.value.promotion_id = 'no-promotion';
      }
    }
  },
  methods: {
    showError(value) {
      this.error = value;
    },
  },
};
</script>
<style lang="scss" scoped>
.pm-select {
  width: 180px;
}
</style>