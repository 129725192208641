<template>
  <div>
    <div v-if="!permissions.assign_discounts || !selectedProperty ">
      <hb-empty-state class="mt-4" :message="getEmptyTextMsg" />
    </div>
    <div v-else>
      <div v-if="(showTable && loading) || hasDiscount">
        <hb-data-table-header>
          <template v-slot:header> Discount Management by Property </template>
          <template v-slot:description>
            Assign and manage discounts per property.
          </template>
          <template v-slot:actions>
            <hb-btn @click="showAssignDiscount" small color="secondary"
              >Assign Discount</hb-btn
            >
          </template>
        </hb-data-table-header>
        <hb-data-table :headers="headers" :items="discounts" :loading="loading">
          <template v-slot:item.name="{ item }">
            <DiscountEffects :discount="item" />
          </template>
          <!-- <template v-slot:item.effects="{ item }">
            <DiscountEffects :discount_effects="item.effects" />
          </template> -->
          <template v-slot:item.eligible_tenants="{ item }">
            <span class="text-capitalize">{{
              item.eligible_tenants?.join(", ")
            }}</span>
          </template>
          <template v-slot:item.assigned_by="{ item }">
            <div>
              <span class="text-capitalize">{{ item.assigned_by }}</span>
              <br />
              <span class="hb-text-light">
                <span class="text-capitalize">{{
                  item.assigned_on | formatDate("MMM DD, YYYY [@] h:mma")
                }}</span>
              </span>
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <hb-menu options align-right>
              <v-list>
                <v-list-item @click="viewDiscount(item)">
                  <v-list-item-title>View Discount</v-list-item-title>
                </v-list-item>
                <v-list-item @click="deleteDiscount(item)">
                  <v-list-item-title>Delete</v-list-item-title>
                </v-list-item>
              </v-list>
            </hb-menu>
          </template>
        </hb-data-table>
      </div>
      <div v-else-if="showTable && !hasDiscount">
        <hb-empty-state
          class="mt-4"
          :message="getEmptyTextMsg"
          btn-txt="Assign Discounts"
          @clicked="showAssignDiscount"
        />
      </div>
      <div v-else>
        <hb-empty-state class="mt-4" :message="getEmptyTextMsg" />
      </div>
    </div>
    <view-discount-modal ref="viewDiscount" @close="$emit('close')" />
    <AssignDiscount
      :propertyDiscounts="propertyDiscountsFormatted"
      ref="assignDiscount"
      @discountAssigned="getPropertyDiscountsData()"
    />
    <RemovePropertyDiscount
      ref="removeDiscount"
      :property="selectedProperty"
      @discountRemoved="getPropertyDiscountsData()"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MultilinePromoViewer from "./MultilinePromoViewer.vue";
import api from "../../../assets/api";
import DiscountEffects from "./DiscountEffects.vue";
import ViewDiscountModal from "./ViewDiscountModal.vue";
import AssignDiscount from "./AssignDiscount.vue";
import RemovePropertyDiscount from "./RemovePropertyDiscount.vue";
import { notificationMixin } from "../../../mixins/notificationMixin";
import { EventBus } from "../../../EventBus";

export default {
  name: "DiscountManagementByProperty",
  mixins: [notificationMixin],
  components: {
    MultilinePromoViewer,
    DiscountEffects,
    ViewDiscountModal,
    AssignDiscount,
    RemovePropertyDiscount,
  },
  props: {
    permissions: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      dialog: false,
      discountData: {},
      headers: [
        {
          text: "Discount",
          value: "name",
          sortable: true,
          align: "left",
        },
        // {
        //   text: "Effect",
        //   value: "effects",
        //   sortable: true,
        //   align: "left",
        // },
        {
          text: "Eligibility",
          value: "eligible_tenants",
          sortable: true,
          align: "left",
        },
        {
          text: "Assigned By",
          value: "assigned_by",
          sortable: true,
          align: "left",
        },
        {
          text: "",
          value: "actions",
          align: "right",
          sortable: false,
          width: 10,
        },
      ],

      property: "",
    };
  },

  computed: {
    ...mapGetters({
      properties: "propertiesStore/filtered",
      selectedProperty: "revManStore/getSelectedProperty",
      activePromotions: "revManStore/getActivePromotions",
      propertyDiscounts: "revManStore/getPropertyDiscounts",
      propertyPromotionSettings: "revManStore/getPropertyPromotionSettings",
    }),
    showTable() {
      return (
        this.selectedProperty &&
        this.permissions.assign_discounts
      );
    },
    getEmptyTextMsg() {
      let text = "";
      if (!this.permissions.assign_discounts) {
        text =
          "You do not have the permission to access this page. Please contact the Admin.";
      } else {
        if (!this.selectedProperty) {
          text = "Please select a property from the dropdown above.";
        } else if (
          this.selectedProperty &&
          !this.hasDiscount
        ) {
          text = "This property doesn't have any assigned discounts";
        }
      }
      return text;
    },
    hasDiscount() {
      return this.propertyDiscounts?.length > 0;
    },
    discounts(){
      return this.propertyDiscounts;
    },
    propertyDiscountsFormatted() {
      return this.propertyDiscounts.map((discount) => ({
        id: discount.id,
        name: discount.name,
      }));
    },
  },

  methods: {
    ...mapActions({
      fetchPropertyDiscounts: 'revManStore/fetchPropertyDiscounts',
    }),
    deleteDiscount(discount) {
      this.$refs.removeDiscount.show(discount);
    },

    showAssignDiscount() {
      this.$refs.assignDiscount.show();
    },

    async getPropertyDiscountsData() {
      if (!this.selectedProperty) return;
      this.loading = true;
      try {
        await this.fetchPropertyDiscounts({property_id: this.selectedProperty});
      } catch (error) {
        this.showMessageNotification({
          type: "error",
          description: error,
        });
      } finally {
        this.loading = false;
      }
    },
    viewDiscount(itemData) {
      this.$refs["viewDiscount"].show(itemData);
    },
  },

  async created() {
    this.getPropertyDiscountsData()
    EventBus.$on("refreshDiscountTable", this.getPropertyDiscountsData);
  },

  destroyed() {
    EventBus.$off("refreshDiscountTable");
  },
};
</script>
<style lang="scss" scoped>
.discount-name {
  border-bottom: 1px dashed;
}
</style>
