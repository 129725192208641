<template>
  <div>
    <div v-if="promo.overrides">
      <div class="pl-6 py-3 hb-text-light">
        Overrides are additional conditions that will override your Occupancy Tiers.
      </div>
      <v-divider></v-divider>
      <div class="px-6 py-3 plan-overrides">
        <span>Priorites are listed from top to bottom.</span>
        <v-expansion-panels class="mb-2" v-model="panel" multiple>
          <hb-expansion-panel
            v-for="(overrides, idx) in sortedOverrides"
            :key="idx"
            class="override-panel"
          >
            <template v-slot:title>
              <div v-if="overrides.type === 'days-vacant'">Days Vacant</div>
              <div v-else>Group Vacancy Count</div>
            </template>
            <template v-slot:content>
              <div class="px-4 py-3">
                <div>
                  If the
                  {{ overrides.type === 'days-vacant' ? 'days vacant is' : 'group vacancy count is' }}
                  {{ getSymbol(overrides.comparison) }} {{ overrides.value }}, then apply the promotion
                </div>
                <div class="pt-3">
                  <div>
                    <span class="plan-name-border"
                      ><MultilinePromoViewer :promotion-id="overrides.promotion_id"
                    /></span>
                  </div>
                </div>
              </div>
            </template>
          </hb-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
    <div v-else class="px-6 py-3">
      <hb-empty-state message="No promotion plan overrides"></hb-empty-state>
    </div>
  </div>
</template>

<script>
import MultilinePromoViewer from "./MultilinePromoViewer.vue";

export default {
  name: "PromotionPlanOverrides",
  components: { MultilinePromoViewer },
  props: {
    promo: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      panel: [0, 1],
    };
  },
  computed: {
    sortedOverrides() {
      return [...this.promo.overrides].sort(
        (a, b) => (a.sort) - (b.sort)
      );
    },
  },
  methods: {
    getSymbol(symbol) {
      const symbolToWord = {
        "=": "equal to",
        ">": "greater than",
        "<": "less than",
        ">=": "greater than or equal to",
        "<=": "less than or equal to",
        "!=": "not equal to",
      };

      return symbolToWord[symbol] || "Unknown";
    },
  },
};
</script>
<style lang="scss" scoped>
.override-panel {
  background-color: inherit !important;
}
</style>
